<template>
  <div class="validator-form">
    <el-form
      ref="validatorForm"
      :model="forgetData"
      :rules="formRules"
      @submit.native.prevent
    >
      <!-- 区号 - 手机号 -->
      <div>
        <div class="mobile-code" v-if="validInfo.validate_mobile">
          <div class="area-mobile">
            <!-- <el-form-item prop="area">
              <el-select v-model="forgetData.area" :disabled="true" filterable>
                <el-option
                  v-for="(item, index) in all_areacode"
                  :value="item.phone_code"
                  :label="'+' + item.phone_code + `               ${item.iso} ${item.iso3} ${item.jp} ${item.kr} ${item.zh_CN} ${item.zh_HK}`"
                  :key="index"
                >
                  <p style="width: 278px; display: flex; justify-content: space-between;">
                    <span>{{ item.zh_CN }}</span>
                    <span>{{ item.phone_code }}</span>
                  </p>
                </el-option>
              </el-select>
            </el-form-item> -->

            <!-- <el-form-item prop="mobile">
              <InputNumber
                v-model="forgetData.mobile"
                placeholder="请输入手机号"
                maxlength="20"
                :disabled="true"
              ></InputNumber>
            </el-form-item> -->
          </div>
          <div class="tips">
            <span>{{ translateTitle("输入您的手机号") }}</span>
            <span class="tips-title">{{
              globalFun.userDecryption(forgetData.mobile, "mobile")
            }}</span>
            <span>{{ translateTitle("收到的验证码") }}</span>
          </div>
          <!-- 手机验证码 -->
          <el-form-item prop="mobile_code">
            <div class="code">
              <InputNumber
                v-model="forgetData.mobile_code"
                :placeholder="translateTitle('手机验证码')"
                maxlength="6"
              ></InputNumber>
              <div class="count-down">
                <span
                  v-if="
                    mobileCountDown.countDown === 0 ||
                      mobileCountDown.countDown === -1
                  "
                  @click="onMobileSendCode"
                  >{{ translateTitle("获取验证码") }}</span
                >
                <span v-else>{{ mobileCountDown.countDown + "s" }}</span>
              </div>
            </div>
          </el-form-item>
        </div>
        <!-- 邮箱 -->
        <div class="email-code">
          <!-- <el-form-item class="email" prop="email" v-if="validInfo.validate_email">
            <el-input
              v-model.trim="forgetData.email"
              :disabled="true"
              maxlength="30"
            ></el-input>
          </el-form-item> -->
          <!-- 邮箱验证码 -->
          <div class="tips" v-if="validInfo.validate_email">
            <span>{{ translateTitle("请输入您的邮箱") }}</span>
            <span class="tips-title">{{
              globalFun.userDecryption(forgetData.email, "email")
            }}</span>
            <span>{{ translateTitle("收到的验证码") }}</span>
          </div>
          <el-form-item prop="email_code" v-if="validInfo.validate_email">
            <div class="code">
              <InputNumber
                v-model="forgetData.email_code"
                :placeholder="translateTitle('邮箱验证码')"
                maxlength="6"
              ></InputNumber>
              <div class="count-down">
                <span
                  v-if="
                    emailCountDown.countDown === 0 ||
                      emailCountDown.countDown === -1
                  "
                  @click="onEmailSendCode"
                  >{{ translateTitle("获取验证码") }}</span
                >
                <span v-else>{{ emailCountDown.countDown + "s" }}</span>
              </div>
            </div>
          </el-form-item>
        </div>
        <!-- 账号 -->
        <div class="email-code">
          <!-- <el-form-item class="username" prop="username">
            <el-input
              v-model.trim="forgetData.username"
              placeholder="请输入账号"
              maxlength="30"
              :disabled="isInCheck"
            ></el-input>
          </el-form-item> -->
        </div>
      </div>
      <el-form-item prop="google_code" v-if="validInfo.validate_google">
        <InputNumber
          v-model="forgetData.google_code"
          :placeholder="translateTitle('谷歌验证码')"
          maxlength="6"
        ></InputNumber>
      </el-form-item>
      <div class="sercurity-title">
        <span class="sercurity" @click="onSercuity">{{
          translateTitle("安全项丢失?")
        }}</span>
      </div>
      <!-- 提交 -->
      <el-button
        class="submit theme-btn"
        :loading="forgetLoading"
        @click="handSubmit"
        >{{ translateTitle("确认") }}</el-button
      >
      <div class="cancel-btns">
        <div @click="onToForget" class="cancel">
          {{ translateTitle("取消") }}
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
// import Code from "./Code";
import * as Settings from "@/model/settings";
import { translateTitle } from "@/assets/js/i18n";
import InputNumber from "@/components/common/InputNumber";
import {
  sendSignSmsCode,
  sendSignEmailCode,
  sendSignSmsCodeGeet,
  sendSignEmailCodeGeet,
  sendForgetSmsCode,
  sendForgetEmailCode,
} from "@/model/user/security";
import { submitForgetUniversal } from "@/model/user/forgetPwd";
import { showMessage } from "@/components/dialog/message";
import routeSplicing from "@/assets/js/routeSplicing";
import { mapGetters } from "vuex";
import globalFun from "@/assets/js/globalFun.js";
export default {
  props: {
    isInCheck: {
      type: Boolean,
      default: false,
    },
    validInfo: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      emailCountDown: {
        countDown: -1, // 倒计时计数
        countDownTimer: null, // 倒计时容器
        countDownStatus: false, // 倒计时点击状态
      },
      mobileCountDown: {
        countDown: -1, // 倒计时计数
        countDownTimer: null, // 倒计时容器
        countDownStatus: false, // 倒计时点击状态
      },
      user_id: "",
      all_areacode: [], // 所有区号
      forgetData: {
        area: 86,
      },
      formRules: {
        mobile_code: [
          {
            required: true,
            message: this.translateTitle(`请输入正确的6位手机验证码`),
            trigger: "blur",
          },
          {
            pattern: /^\d{6}$/,
            message: this.translateTitle(`请输入正确的6位手机验证码`),
            trigger: "blur",
          },
        ],
        email_code: [
          {
            required: true,
            message: this.translateTitle(`请输入正确的6位邮箱验证码`),
            trigger: "blur",
          },
          {
            pattern: /^\d{6}$/,
            message: this.translateTitle(`请输入正确的6位邮箱验证码`),
            trigger: "blur",
          },
        ],
        google_code: [
          {
            required: true,
            message: this.translateTitle(`请输入正确的6位谷歌验证码`),
            trigger: "blur",
          },
          {
            pattern: /^\d{6}$/,
            message: this.translateTitle(`请输入正确的6位谷歌验证码`),
            trigger: "blur",
          },
        ],
      },
      valid: {
        email: true,
        mobile: true,
        google: false,
        username: true,
      },
      forgetLoading: false,
      mobileCounting: false,
      emailCounting: false,
      globalFun,
    };
  },
  created() {
    this.getAreaCode();

    document.onkeyup = e => {
      let keycode = document.all ? event.keyCode : e.which;
      // 在登录界面 且 按得键是回车键enter
      if (this.$route.path == "/forgetPwd" && keycode === 13) {
        this.handSubmit();
      }
    };
  },
  watch: {
    validInfo: {
      handler(val) {
        this.forgetData.email = val.email;
        this.forgetData.mobile = val.mobile;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  methods: {
    routeSplicing,
    translateTitle,
    onToForget() {
      this.$emit("onCancel");
    },
    onSercuity() {
      this.$router.push(this.routeSplicing(this.language, "loginSecurity"));
    },
    // 发送手机验证码
    onMobileSendCode() {
      const data = {
        // type: 23,
        // mobile: this.validInfo.mobile,
        // area: this.validInfo.area,
        // user_id: this.validInfo.user_id,
        token: this.validInfo.token,
      };
      sendForgetSmsCode(data).then(rs => {
        if (rs.status == 200) {
          this.mobileCounting = true;
          showMessage({
            message: this.translateTitle(`发送成功`),
            type: "success",
          });
          this.mobileCountDown.countDown = 60;
          this.mobileCountDown.countDownTimer = setInterval(() => {
            if (this.mobileCountDown.countDown === 0) {
              clearInterval(this.mobileCountDown.countDownTimer);
              this.mobileCountDown.countDownStatus = false;
            }
            this.mobileCountDown.countDown--;
          }, 1000);
        } else {
          this.mobileCountDown.countDownStatus = false;
        }
      });
    },
    // 发送邮箱验证码
    onEmailSendCode() {
      const data = {
        // type: 24,
        // email: this.validInfo.email,
        // user_id: this.validInfo.user_id,
        token: this.validInfo.token,
      };
      sendForgetEmailCode(data).then(rs => {
        if (rs.status == 200) {
          this.emailCounting = true;
          showMessage({
            message: this.translateTitle(`发送成功`),
            type: "success",
          });
          this.emailCountDown.countDown = 60;
          this.emailCountDown.countDownTimer = setInterval(() => {
            if (this.emailCountDown.countDown === 0) {
              clearInterval(this.emailCountDown.countDownTimer);
              this.emailCountDown.countDownStatus = false;
            }
            this.emailCountDown.countDown--;
          }, 1000);
        } else {
          this.emailCountDown.countDownStatus = false;
        }
      });
    },
    handSubmit() {
      this.$refs.validatorForm.validate(valid => {
        if (valid) {
          this.forgetLoading = true;
          // 检测是否存在该账号
          const data = {};
          const {
            token,
            validate_email,
            validate_google,
            validate_mobile,
            email,
            mobile,
            user_id,
            username,
          } = this.validInfo;
          data.token = token;
          // data.user_id = user_id;
          if (validate_email == 1) {
            // (data.email = email),
            data.email_code = this.forgetData.email_code;
          }
          if (validate_mobile == 1) {
            // (data.mobile = mobile),
            data.mobile_code = this.forgetData.mobile_code;
          }
          if (validate_google == 1) {
            // (data.username = username),
            data.google_code = this.forgetData.google_code;
          }

          submitForgetUniversal(data).then(rs => {
            this.forgetLoading = false;
            if (rs.status == 200) {
              this.$emit("secret", rs.data.token);
            }
          });
        }
        return;
      });
    },
    /* 获取手机区号 */
    async getAreaCode() {
      const { data } = await Settings.apiGetStaticTableInfo(
        Settings.SETTING_SV_ID.static_sms_country
      );
      if (!data) {
        return false;
      }
      this.all_areacode = data;
    },
  },
  components: { InputNumber },
};
</script>
<style lang="scss">
/* 表单数据 */
.validator-form {
  .el-form {
    input {
      // height: 44px;
      border-radius: 2px;
      &:focus {
        border-color: #c61b3d;
      }
    }
    .tips {
      span {
        font-size: 12px;
        color: #9aa1b1;
        height: 16px;
        font-weight: 400;
      }

      .tips-title {
        font-size: 12px;
        color: #354052;
        height: 16px;
        font-weight: 400;
        padding: 0px 5px;
      }
    }

    /* 区号-手机号 */
    .area-mobile {
      margin-top: 0px;
      // display: flex;
      .el-form-item {
        .el-select .el-input.is-focus .el-input__inner {
          border-color: #c61b3d;
        }
        &:first-child {
          width: 89.7px;
          margin-right: 6px;
          input {
            padding-right: 20px;
          }
        }
        &:last-child {
          flex: 1;
        }
      }
    }
  }

  /* 每一项 */
  .el-form-item {
    padding-top: 0px;
    margin-bottom: 15px;

    /* 验证码 */
    .code {
      display: flex;
      justify-content: space-between;
      .el-input {
        width: 400px;
      }
      .number {
        flex: 1;
        margin-right: 9px;
      }
      .count-down {
        width: 80px;
        color: #3a7dff;
        line-height: 1em;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);

        span {
          cursor: pointer;
        }
      }
    }
  }
  .sercurity-title {
    margin: -1px 0px 20px;
    text-align: left;
    .sercurity {
      color: #3a7dff;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }

  /* 提交 */
  .submit {
    width: 100%;
    height: 48px;
    color: #fff;
    font-size: 16px;
    border: 0px;
    line-height: 48px;
    text-align: center;
    background-image: linear-gradient(267deg, #ff486d 10%, #c61b3d 41%);
    border: 0;
    cursor: pointer;
    padding: 0;
    &:hover {
      background-image: linear-gradient(267deg, #ff486d 10%, #c61b3d 41%);
    }
  }
  .cancel-btns {
    margin-top: 20px;
    text-align: center;
    .cancel {
      color: #3a7dff;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
</style>
