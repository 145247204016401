<template>
  <div class="login_container">
    <ForgetPwdInner v-if="defaultPage"></ForgetPwdInner>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import ForgetPwdInner from "@/pages/login/forgetPwd-inner.vue";
export default {
  mixins: [],

  data() {
    return {
      is8V: false,
      defaultPage: true,
    };
  },
  components: { ForgetPwdInner },
  computed: {},
  created() {},
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
