<template>
  <div class="confirmPwd">
    <el-form
      ref="confirm_ref"
      :model="pwdData"
      :rules="formRules"
      @submit.native.prevent
    >
      <el-form-item prop="pwd" :class="{ pwd: true, showLevel: showLevel }">
        <el-input
          autocomplete="off"
          v-model="pwdData.pwd"
          :placeholder="translateTitle('新密码')"
          type="password"
          v-focus="pwdFocus"
          @focus="showLevel = true"
          @blur="pwdBlur"
          @input="checkChange"
          maxlength="20"
        ></el-input>
        <div class="pwd-level" v-if="showLevel">
          <p class="level-bar">
            <span
              :class="{
                'gray-bar': true,
                'red-bar': pwdLevel == 1,
                'orange-bar': pwdLevel == 2,
                'green-bar': pwdLevel == 3,
              }"
            ></span>
            <span
              :class="{
                'gray-bar': true,
                'orange-bar': pwdLevel == 2,
                'green-bar': pwdLevel == 3,
              }"
            ></span>
            <span
              :class="{ 'gray-bar': true, 'green-bar': pwdLevel == 3 }"
            ></span>
            <i
              :class="{
                'level-text': true,
                redC: pwdLevel == 1,
                orangeC: pwdLevel == 2,
                grrenC: pwdLevel == 3,
              }"
              >{{ translateTitle(levelText) }}</i
            >
          </p>
          <div class="level-text">
            <p>
              <i :class="{ 'green-spot': spotLevel.digit }"></i>
              {{ translateTitle("8-20位字符") }}
            </p>
            <p>
              <i :class="{ 'green-spot': spotLevel.enNum }"></i>
              {{ translateTitle("必须包含字母和数字，不能包含空格") }}
            </p>
            <p>
              <i :class="{ 'green-spot': spotLevel.symbol }"></i>
              {{ translateTitle("建议包含标点符号") }}
            </p>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="confirmPwd" class="confirm-pwd">
        <el-input
          :class="{ confirmOutline: confirmOutline }"
          autocomplete="off"
          v-model="pwdData.confirmPwd"
          :placeholder="translateTitle('确认密码')"
          type="password"
          v-focus="confirmFocus"
          maxlength="20"
        ></el-input>
      </el-form-item>
      <!-- <div class="reset-btn" @click="resetPwd">重置密码</div> -->
      <el-button
        class="reset-btn theme-btn"
        :loading="resetLoading"
        @click="resetPwd"
        >{{ translateTitle("重置密码") }}</el-button
      >
    </el-form>
  </div>
</template>
<script>
import md5 from "js-md5";
import { showMessage } from "@/components/dialog/message";
import { forgetCheckUpdate } from "@/model/user/forgetPwd";
import { translateTitle } from "@/assets/js/i18n";
export default {
  directives: {
    focus: {
      update: (el, { value, oldValue }) => {
        if (value != oldValue) {
          el.querySelector("input").focus();
        }
      },
    },
  },
  props: ["onlyName", "token"],
  data() {
    return {
      pwdData: {},
      pwdLevel: 0,
      showLevel: false,
      pwdFocus: false,
      confirmFocus: false,
      spotLevel: {},
      formRules: {
        confirmPwd: [{ validator: this.confirmBlur, trigger: "change" }],
      },
      confirmOutline: false,
      resetLoading: false,
    };
  },
  created() {
    this.initialData();
  },
  methods: {
    translateTitle,
    // 密码框失焦
    pwdBlur() {
      this.showLevel = false;
    },
    // 确认密码
    confirmBlur(rule, value, callback) {
      let { pwd } = this.pwdData;
      if ((value === "") | !value) {
        callback(new Error(this.translateTitle("请输入确认密码")));
      } else if (value !== pwd) {
        this.confirmOutline = true;
        callback(new Error(this.translateTitle("两次输入密码不一致!")));
      } else {
        callback();
      }
    },
    // 重置密码点击
    resetPwd() {
      let {
        pwdData: { pwd, confirmPwd },
        spotLevel: { digit, enNum },
      } = this;
      if (digit && enNum) {
        if (pwd !== confirmPwd) {
          this.confirmFocus = !this.confirmFocus;
        } else {
          this.confirmOutline = false;
          this.submitNewPwd();
        }
      } else {
        this.pwdFocus = !this.pwdFocus;
      }
    },
    // 密码提交
    async submitNewPwd() {
      let {
        pwdData: { pwd },
      } = this;
      // let only_name = this.onlyName;
      pwd = md5(pwd);
      this.resetLoading = true;
      // let { msg, status } = await forgetCheckUpdate({ pwd, only_name });
      let { msg, status } = await forgetCheckUpdate({
        pwd,
        token: this.token,
      });
      if (status == 200) {
        // 去登录页
        this.resetLoading = false;
        showMessage({
          message: this.translateTitle(`设置新密码成功`),
          type: "success",
        });
        this.$router.push({ name: "Login" });
      } else {
        this.resetLoading = false;
        showMessage({ message: msg ? msg : status });
      }
    },
    // 检测密码是否合规
    checkChange() {
      let { pwd } = this.pwdData;
      let length = pwd.length;

      if (!pwd || /^\s$/.test(pwd)) {
        this.initialData();
      } else if (length < 8) {
        // 一级灰点
        this.spotLevel.digit = false;
        this.check_level(pwd);
        this.pwdLevel = 0;
      } else if (8 <= length && length < 10) {
        // 一级绿点
        this.spotLevel.digit = true;
        this.check_level(pwd);
      } else {
        this.spotLevel.digit = true;
        this.check_level(pwd);
      }
    },
    // 检测英文数字：必须包含英文和数字，允许包含符号（不允许包含空格）
    check_En_Num(val) {
      if (
        /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9?=.*[·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/.test(
          val
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 检测符号：必须包含符号，允许包含 英文，数字，空格
    check_En_Num_Symbol(val) {
      if (
        /^(?=.*[·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+)[\sa-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/.test(
          val
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    check_level(pwd) {
      let length = pwd.length;
      if (this.check_En_Num(pwd)) {
        // 二级绿点
        this.spotLevel.enNum = true;
        // 密码等级
        if (6 <= length && length < 8) {
          this.pwdLevel = 1;
        } else if (length >= 8) {
          this.pwdLevel = 2;
        }
      } else {
        // 二级灰点
        this.spotLevel.enNum = false;
      }
      if (this.check_En_Num_Symbol(pwd)) {
        // 三级绿点
        this.spotLevel.symbol = true;
        // 密码等级
        if (6 <= length && length < 8) {
          this.pwdLevel = 1;
        } else if (length >= 8) {
          if (this.check_En_Num(pwd)) {
            this.pwdLevel = 3;
          }
        }
      } else {
        // 三级灰点
        this.spotLevel.symbol = false;
      }

      if (/^[a-zA-Z]*$/.test(pwd)) this.pwdLevel = 0;
      if (/^[0-9]*$/.test(pwd)) this.pwdLevel = 0;
      if (
        /^[?=.*[·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]*$/.test(
          pwd
        )
      )
        this.pwdLevel = 0;
      if (
        /^[0-9?=.*[·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]*$/.test(
          pwd
        )
      )
        this.pwdLevel = 0;
      if (
        /^[a-zA-Z?=.*[·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]*$/.test(
          pwd
        )
      )
        this.pwdLevel = 0;
      if (!this.check_En_Num(pwd)) this.pwdLevel = 0;
    },
    initialData() {
      this.pwdLevel = 0;
      this.spotLevel = {
        digit: false,
        enNum: false,
        symbol: false,
      };
    },
  },
  computed: {
    levelText() {
      let tempLevel = "";
      switch (this.pwdLevel) {
        case 0:
          tempLevel = "";
          break;
        case 1:
          tempLevel = "弱";
          break;
        case 2:
          tempLevel = "中";
          break;
        case 3:
          tempLevel = "强";
      }
      return tempLevel;
    },
  },
};
</script>
<style lang="scss">
.confirmPwd {
  /* 提示 */
  .tips {
    color: #333;
    height: 60px;
    font-size: 12px;
    line-height: 17px;
    border-radius: 4px;
    border: 1px solid rgba(245, 221, 180, 1);
    background-color: rgba(253, 250, 245, 1);
    padding: 12px 10px 14px;
    margin-top: 16px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  /* 表单 */
  .el-form {
    input {
      height: 44px;
      border-radius: 2px;
      &:focus {
        border-color: #c61b3d;
      }
    }

    .pwd {
      &.showLevel {
        margin-bottom: 8px;
      }

      .pwd-level {
        padding: 7px 8px;
        .level-bar {
          display: flex;
          align-items: center;
          height: 20px;
          span {
            display: block;
            width: 65px;
            height: 4px;
            background-color: #e7e7e7;
            margin-right: 2px;

            &.red-bar {
              background-color: #f43932;
            }
            &.orange-bar {
              background-color: #ffc100;
            }
            &.green-bar {
              background-color: #c61b3d;
            }
          }
          i {
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            font-style: normal;
            margin-left: 14px;
            &.redC {
              color: #f43932;
            }
            &.orangeC {
              color: #ffc100;
            }
            &.grrenC {
              color: #03ad79;
            }
          }
        }
        .level-text {
          height: 60px;
          p {
            height: 20px;
            color: #a2a5ae;
            display: flex;
            align-items: center;

            i {
              display: block;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #e7e7e7;
              margin-right: 7px;
              &.green-spot {
                background-color: #03ad79;
              }
            }
          }
        }
      }
    }

    .confirm-pwd {
      .el-input {
        &.confirmOutline {
          input {
            border-color: #f43932;
          }
        }
      }
    }

    /* 重置密码按钮 */
    .reset-btn {
      width: 100%;
      height: 48px;
      color: #fff;
      font-size: 14px;
      line-height: 48px;
      font-weight: 400;
      text-align: center;
      background-image: linear-gradient(267deg, #ff486d 10%, #c61b3d 41%);
      border-radius: 2px;
      border: 0;
      cursor: pointer;
      padding: 0;
      &:hover {
        background-image: linear-gradient(267deg, #ff486d 10%, #c61b3d 41%);
      }
    }
  }
}
</style>
